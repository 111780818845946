/* consulting styles */
.spacer, .blob {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .spacer {
    aspect-ratio: 900/100;
  }
  .blob {
    aspect-ratio: 960/540;
  }
  .layer-primary {
    background-image: url('../images/spacer2.svg');
  }
  
  .bg-blob {
    background-image: url('../images/blob-bg.svg');
  }