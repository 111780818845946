@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat&display=swap);

/* colors 
#FFF White
#BFC0C0 Silver
#EF8354 Mandarin (orange)
#4F5D75 Independence  (lighter dark silver / grey )
#2D3142 Space Cadet (dark silver / grey) */
html {
  scroll-behavior: smooth;
}

body {
  /* background: rgb(191,192,192);
  background: linear-gradient(170deg, rgba(191,192,192,1) 0%, rgba(79,93,117,1) 42%, rgba(45,49,66,1) 100%); */
  font-family: 'Montserrat', sans-serif;
  background-color: #BFC0C0;
  margin: 0;
  padding: 0;
}

.text-center {
  text-align: center;
}

.show-button {
  cursor: pointer;
}

#about {
  padding: 1rem;
}


/* margin and padding utilities */
.mt-3 {
  margin-top: 3rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pt-6 {
  padding-top: 12rem;
}

/* text styles */
h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  font-family: 'Bebas Neue', cursive;
}

h1 {
  font-size: 300%;
}

h2 {
  font-size: 200%;
}

h3 {
  font-size: 190%;
}

h4 {
  font-size: 180%;
}

.text-white {
  color: #FFF;
}
.text-emphasis {
  color: #EF8354;
}
.text-silver {
  color: #2D3142;
}
.big-text {
  font-size: 300%;
}
.big-text-header {
  font-size: 1200%;
}
.big-text-p {
  font-size: 120%;
}


.bg-dark {
  background-color: #2D3142;
}
.bg-light {
  background-color: #4f5d75;
}
.bg-primary {
  background-color: #BFC0C0;
}

.container, .rounded-container {
  max-width: 1080px;
  margin: auto;
  padding: 0 0.5rem;
}

.rounded-container {
  border: black 2px solid;
  border-radius: 0.3rem;
  padding: 0.3rem;
}

.link-img {
  height: 16px;
  -webkit-align-self: stretch;
          align-self: stretch;
}
#intro-text {
  -webkit-animation-name: in-from-right;
          animation-name: in-from-right;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay:0s;
          animation-delay:0s;
  margin: 5% 0 0 0;
  opacity: 1;
}

#intro-img {
  -webkit-animation-name: in-from-left;
          animation-name: in-from-left;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

ul {
  margin: 0;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.reverse-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.row-center, .row-center-col {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.row-center-col {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.row-center a, .row-center h1 {
  margin: 1rem;
}

.row-center div {
  margin: 0.3rem;
}

.col-100 {
  -webkit-flex:100% 1;
          flex:100% 1;
}
.col, .col-50, .col-25, .col-75 {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0.3rem;
}

.col-50 {
  -webkit-flex: 2 1;
          flex: 2 1;
}
.col-75 {
  -webkit-flex: 3 1;
          flex: 3 1;
}
.img {
  height: auto;
  width: 100%;
}

.rounded-border {
  border: 10px solid #EF8354;
  border-radius: 1%;
}
a {
  text-decoration: none;
  color: white;
}

.p-1 {
  padding: 0.3rem;
}

.ml {
  margin-left: 1rem;
}

.my {
  margin: 1rem 0;
}

.skill-list:hover, .nav-link:hover {
  color: #EF8354;
  cursor: pointer;
}

.tag {
  background-color: #2D3142;
  border: 1px solid #2D3142;
  color:#EF8354;
  margin: 0.3rem;
  border-radius: 20%;
  padding: 0.5rem;
}

.tag:hover {
  cursor: pointer;
}


/* project styles */
.project {
  border: black 2px solid;
  border-radius: 0.3rem;
  margin: 0.3rem;
}

.project-header {
  padding: 0.5rem;
}
.project-header h3 {
  padding: 0;
  margin: 0;
  text-align: center;
}
.project-body {
  border-top: 1px solid black;
  padding: 1rem;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.btn {
  border: 2px solid #EF8354;
  background-color: #EF8354;
  border-radius: 5%;
  padding: 0.5rem;
  -webkit-align-self: center;
          align-self: center;
}

.project-img {
  max-height: 500px;
  width: auto;
}

.project-tag {
  color:#EF8354;
  margin: 0.2rem;
  padding: 0.2rem;
}
.project-img-container {
  text-align: center;
  vertical-align: middle;
}
/* project styles end */

/* footer styles */
.footer-img-container {
  text-align: center;
  vertical-align: middle;
}
/* footer styles end */

.nav-links {
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 0.7rem;
}

.navbar {
  padding: 0.3rem;
}

.nav-link {
  list-style: none;
}

.nav-link a:hover {
  color:#EF8354;
}

#mobile-nav {
  display: none;
}

#navbar {
  width: 100%;
}

#landing {
  background-image: linear-gradient(to right,#4f5d75,#2D3142 100%),url(/static/media/text.62955997.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
}

.h100 {
  min-height: 100vh;
}

.w100 {
  width: 100%;
}

/* landing page styles */
.rounded-bg-div {
  background-color: #0d142e;
  border: 1px solid #2D3142;
  color:#EF8354;
  margin: 0.3rem;
  border-radius: 20%;
  padding: 3rem;
}

@media (max-width: 780px) {
  .col, .col-50, .col-25, .col-75 {
    -webkit-flex:100% 1;
            flex:100% 1;
  }
  .col {
    -webkit-flex: 2 1;
            flex: 2 1;
    margin: 0;
  }
  .container {
    margin: auto;
  }
  .project-img {
    max-width: 100%;
  }
  .project-img-container {
    height: auto;
  }
  #duties {
    margin-top: 1rem;
  }
  #mobile-nav {
    display:block;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  #mobile-nav div {
    -webkit-transform: scale(2,2);
            transform: scale(2,2);
  }
  #navbar {
    display: none;
  }

  .open-menu {
    -webkit-animation-name: open-menu;
            animation-name: open-menu;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-delay:0s;
            animation-delay:0s;
  }

  .nav-links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    width: 100%;
  }
  .nav-link {
    padding-bottom: 0.2rem;
  }
  .reverse-row {
    -webkit-flex-wrap:wrap-reverse;
            flex-wrap:wrap-reverse;
  }
  .rounded-bg-div {
    background-color: #0d142e;
    border: 1px solid #2D3142;
    color:#EF8354;
    border-radius: 20%;
    padding: 1rem;
    margin: 0.3rem;
  }

  .big-text {
    font-size: 100%;
  }
  .big-text-header {
    font-size: 300%;
  }
  .pt-6 {
    padding-top: 1rem;
  }
  .bg-blob {
    background-color: white;
  }
}

/* animations */
.slide-in-from-right {
  -webkit-animation-name: in-from-right;
          animation-name: in-from-right;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay:0s;
          animation-delay:0s;
}

.slide-in-from-left {
  -webkit-animation-name: in-from-left;
          animation-name: in-from-left;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay:0s;
          animation-delay:0s;
}

.slide-in-from-top {
  -webkit-animation-name: in-from-top;
          animation-name: in-from-top;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay:0s;
          animation-delay:0s;
}

.fade-in {
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-delay:0s;
          animation-delay:0s;
}

/* keyframes */
@-webkit-keyframes in-from-left {
  0% { -webkit-transform: translateX(-100%); transform: translateX(-100%)}
  100% {-webkit-transform: translateX(0%);transform: translateX(0%);}
}
@keyframes in-from-left {
  0% { -webkit-transform: translateX(-100%); transform: translateX(-100%)}
  100% {-webkit-transform: translateX(0%);transform: translateX(0%);}
}

@-webkit-keyframes in-from-right {
  0% {-webkit-transform: translateX(100%);transform: translateX(100%)}
  1% {opacity: 0;}
  99% {opacity: 1;}
  100% {-webkit-transform: translateX(0%);transform: translateX(0%);}
}

@keyframes in-from-right {
  0% {-webkit-transform: translateX(100%);transform: translateX(100%)}
  1% {opacity: 0;}
  99% {opacity: 1;}
  100% {-webkit-transform: translateX(0%);transform: translateX(0%);}
}

@-webkit-keyframes fade-in {
  0% {opacity: 0%;}
  50% {opacity: 0%;}
  100% {opacity: 100%;}
}

@keyframes fade-in {
  0% {opacity: 0%;}
  50% {opacity: 0%;}
  100% {opacity: 100%;}
}

@-webkit-keyframes in-from-top {
  0% {-webkit-transform: translateY(100%);transform: translateY(100%)}
  1% {opacity: 0;}
  99% {opacity: 1;}
  100% {-webkit-transform: translateY(0%);transform: translateY(0%);}
}

@keyframes in-from-top {
  0% {-webkit-transform: translateY(100%);transform: translateY(100%)}
  1% {opacity: 0;}
  99% {opacity: 1;}
  100% {-webkit-transform: translateY(0%);transform: translateY(0%);}
}

@-webkit-keyframes open-menu {
  0% {display: none}
  100% {display: -webkit-flex;display: flex}
}

@keyframes open-menu {
  0% {display: none}
  100% {display: -webkit-flex;display: flex}
}
/* consulting styles */
.spacer, .blob {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .spacer {
    aspect-ratio: 900/100;
  }
  .blob {
    aspect-ratio: 960/540;
  }
  .layer-primary {
    background-image: url(/static/media/spacer2.13bf25d7.svg);
  }
  
  .bg-blob {
    background-image: url(/static/media/blob-bg.bf33401b.svg);
  }
